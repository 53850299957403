<template>
  <div id="FlightDelayReport" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-36">Passenger Report</div>
      </div>
    </div>
    <div id="filltertReport" class="wrap-Main">
      <div class="box-S4">
        <div class="N-Page T-size-24">Filter</div>
      </div>
      <div class="box-S4">
        <div class="B-carddetail flex-between-center">
          <div class="box-S3 noPadding">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="0"
              :nudge-top="0"
              transition="scale-transition"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  class="hideMessage input-date"
                  append-icon="mdi-calendar-range"
                  v-model="picker"
                  label="Select Date"
                  readonly
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="picker"
                type="month"
                @change="SelectListPassengerReport(picker)"
                @input="menu = false"
              ></v-date-picker>
            </v-menu>
          </div>

          <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListCustomer"
              v-model="selectowner"
              item-text="o_name"
              item-value="o_id"
              label="Customer"
              return-object
              @change="SelectListPassengerReport()"
            ></v-select>
          </div>
         
         <div class="box-S3 noPadding">
            <v-select
              class="hideMessage"
              :items="itemsListDeparture"
              v-model="selectDeparture"
              item-text="ap_name"
              item-value="ap_id"
              label="Departure From"
              return-object
              @change="departureFromChange"
            ></v-select>
          </div>
        </div>
      </div>
    </div>

    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-data-table :headers="headers" :items="items" style="width: auto">
          <template v-slot:item.date="{ item }">
            {{ item.date }}
          </template>
        </v-data-table>
      </div>
    </div>

    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>
</template>


<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  lastDayOfYear,
  format,
  getDate,
} from "date-fns";
import dayjs from "dayjs";
import moment from "moment";
import { dateFormat } from "highcharts";
export default {
  data: () => ({
    headers: [
      { value: "date", text: "Date", sortable: true },
      { value: "time", text: "Time", sortable: true },
      { value: "flight_no", text: "Flight No", sortable: true },
      { value: "passenger", text: "Passenger(Book)", sortable: true },
      {
        value: "passenger_actual",
        text: "Passenger (Actual)",
        sortable: true,
      },
      { value: "body_weight", text: "Body Weight(Book)", sortable: true },
      {
        value: "body_weight_actual",
        text: "Body Weight (Actual)",
        sortable: true,
      },
      { value: "bag_weight", text: "Bag Weight(Book)", sortable: true },
      {
        value: "bag_weight_actual",
        text: "Bag Weight (Actual)",
        sortable: true,
      },
      // { value: "action", text: "", sortable: false },
    ],
    userdata: null,
    itemsListCustomer: [],
    selectowner: "",

    items: [],

    picker: {},
    menu: false,

    itemsListDeparture: [],
    selectDeparture: {},
  }),
  computed: {},
  async mounted() {
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.picker = this.dateSelect;
    await this.SelectListOwner();
    await this.renderListDeparture();
    await this.renderUi();
  },

  methods: {
    async SelectListOwner() {
      try {
        const q = {};
        //Add
        if (
          this.userdata.r_id == 4 ||
          this.userdata.r_id == 5 ||
          this.userdata.r_id == 9
        ) {
          q.o_id = 0;
        } else {
          q.o_id = this.userdata.o_id;
        }
        var res = await feathersClientUOA
          .service("customer")
          .find({ query: q });
        this.itemsListCustomer = res.data;

        this.selectowner = {
          o_id: res.data[0].o_id,
          o_name: res.data[0].o_name,
        };
        this.picker = format(new Date(), "yyyy-MM");
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
    },

    async renderUi() {
      this.items = [];
      const q = {};
      q.month = this.picker.substr(5, 2);
      q.year = this.picker.substr(0, 4);
      q.ap_id = this.selectDeparture.ap_id;

      if (this.userdata.r_id == "2") q.customerid = this.userdata.o_id;
      else q.o_id = itemsListCustomer.o_id;
      var res = await feathersClientUOA
        .service("passengerreport")
        .find({ query: q });

      console.log(res[0]);
      for (let i = 0; i < res.length; i++) {
        this.items.push({
          date: dayjs(res[i].Date).format("DD-MMM-YYYY").substring(0,11),
          time: res[i].departure,
          flight_no: res[i].f_flight_no,
          passenger: res[i].Passenger,
          passenger_actual: res[i].Passenger_ac,
          body_weight: res[i].body_weight,
          body_weight_actual: res[i].body_weight_ac,
          bag_weight: res[i].bag,
          bag_weight_actual: res[i].bag_ac,
        });
      }
    },

    async SelectListPassengerReport(arg) {
      this.picker = arg;
      await this.renderUi();
    },

    async renderListDeparture() {
      try {
        var data = await feathersClientUOA.service("mastercommand").find({});
        var airports = data[0].airport.filter((x) => x.ap_checkin == true);
        airports.forEach((element) => {
          this.itemsListDeparture.push(element);
        });
      } catch (error) {
        console.log(error);
        alert("ไม่สามารถขอข้อมูลจาก server ได้");
      }
      await this.selectDepartureFrom();
    },

    async selectDepartureFrom() {
      try {
        let departureChange =
          await this.$LocalStorageService.getDepartureChange();
        if (departureChange == null) {
          this.selectDeparture = {
            ap_id: this.itemsListDeparture[0].ap_id,
            ap_name: this.itemsListDeparture[0].ap_name,
          };
        } else {
          let obj = JSON.parse(departureChange);
          this.selectDeparture = { ap_id: obj.ap_id, ap_name: obj.ap_name };
        }
      } catch (error) {
        console.log(
          "SelectListCustomer ไม่สามารถขอข้อมูลจาก server ได้ /nError : " +
            error
        );
      }
    },

    async departureFromChange(arg) {
      this.$LocalStorageService.setDepartureChange(
        this.selectDeparture.ap_id,
        this.selectDeparture.ap_name
      );
      await this.renderUi();
    },
  },
};
</script> 


<style>
</style>